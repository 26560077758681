<template>
  <div>
    <h1 className="grafic-font">Dobrovoľný hasičský zbor Jedľové Kostoľany/DHZ Jedľové Kostoľany</h1>
    <br/>
    <p>Dobrovoľný hasičský zbor Jedľové Kostoľany (DHZ Jedľové Kostoľany) je organizácia zameraná na ochranu obce
      Jedľové Kostoľany a okolitých oblastí pred požiarmi a inými núdzovými situáciami. Členovia DHZ Jedľové Kostoľany
      sú dobrovoľní hasiči, ktorí sa venujú prevencii požiarov, záchrane životov a majetku, ako aj vzdelávaniu a
      informovaniu obyvateľstva o bezpečnosti a ochrane pred požiarmi.</p>
    <p>Títo dobrovoľníci sa tiež zúčastňujú rôznych školení, kurzov a súťaží s cieľom zlepšovať svoje zručnosti a byť
      pripravení čeliť rôznym núdzovým situáciám. Dobrovoľný hasičský zbor Jedľové Kostoľany sa podieľa na posilňovaní
      komunity a pomáha pri organizovaní a zabezpečovaní miestnych kultúrnych a športových podujatí.</p>
    <br/>
  </div>
</template>

<script>

export default {
  name: 'JedloveKostolanyComponent',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media (max-width: 868px) {

}

@media (min-width: 868px) {
  p {
    font-size: 120%;
  }
}
</style>