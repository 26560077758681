<template>
  <footer class="sticky-footer">
    <div class="footer-content">
      <div class="instagramIconDiv">
        <a href="https://www.instagram.com/mysmejedlovekostolany.sk/" target="_blank" rel="noopener noreferrer">
          <font-awesome-icon :icon="instagramIcon" class="instagram-icon"/>
          <p class="odkazText">mysmejedlovekostolany.sk</p>
        </a>
      </div>
      <div class="instagramIconDiv">
        <a href="mailto:info@mysmejedlovekostolany.sk" target="_blank" rel="noopener noreferrer">
          <font-awesome-icon :icon="emailIcon" class="instagram-icon"/>
          <p class="odkazText">info@mysmejedlovekostolany.sk</p>
        </a>
      </div>
      <div class="footer-logo">
        <div class="image-wrapper">
          <img src="@/assets/logo/kostolany.png" alt="Jedlove Kostolany" width="70" height="50">
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

export default {
  name: "FooterPanel",
  computed: {
    instagramIcon() {
      return faInstagram;
    },
    emailIcon() {
      return faEnvelope;
    }
  }
}
</script>

<style scoped>

footer {
  bottom: -60px;
  width: 100%;
  height: 60px;
  background: #131313;
}

.sticky-footer {
  position: sticky;
  top: 100%;
}

.footer-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: 0 20px;
}

.footer-logo {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.instagramIconDiv {
  display: flex;
  align-items: center;
}

.instagram-icon {
  font-size: 24px;
  color: #ffffff;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
}

.odkazText {
  color: white;
}

@media (max-width: 868px) {
  .odkazText {
    display: none;
  }
}

</style>


