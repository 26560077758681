<template>
  <header-panel/>
  <router-view/>
  <footer-panel/>
</template>

<script>
import HeaderPanel from './components/HeaderPanel.vue'
import FooterPanel from './components/FooterPanel.vue'
export default {
  name: 'App',
  components: {
    HeaderPanel,
    FooterPanel
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}


@font-face {
  font-family: "Sancreek";
  src: local("Sancreek"),
  url("@/assets/fonts/Sancreek-Regular.ttf") format("truetype");
}
</style>
