<template>
      <banner-panel/>
  <div class="content-grid">
    <div class="sidenav">

    </div>

    <div class="main" style="min-height: 60vh;">
    </div>
  </div>
</template>

<script>

import BannerPanel from "@/components/BannerPanel";
export default {
  name: 'StoryPage',
  components: {BannerPanel},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  font-family: "Lato", sans-serif;
}

.sidenav {
  width: available;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
}

.sidenav a {
  padding: 6px 8px 6px 16px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
}

.sidenav a:hover {
  color: #f1f1f1;
}


@media (min-width: 868px) {
  .sidenav {
    border-top-right-radius: 50px;
  }
  .content-grid {
    display: grid;
    grid-template-columns: 15% 85% ;
  }
}
</style>
