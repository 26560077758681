<template>
  <banner-panel></banner-panel>
  <div class="content" role="main">

    <h1 class="grafic-font">My sme Jedľové Kostoľany</h1>
    <div class="content-grid" style="min-height: 55vh">
      <div class="left-grid"></div>
      <div class="center-grid">
        <p class="intro">Srdečne Vás vítame na Vašej stránke. Áno, čítate správne, NA VAŠEJ.</p>
        <p>Toto je miesto pre všetkých, ktorí sa chcú dozvedieť zaujímavé informácie o dianí v obci, o zaujímavých organizáciách a výnimočných ľuďoch z Jedľových Kostolian.
          Je to miesto, kde obyvatelia našej obce môžu prezentovať seba, svoju činnosť, či prácu. Môžu zdieľať s verejnosťou ich nové nápady, realizovať projekty, či zdieľať informácie a skúsenosti.</p>
        <p>Nájdete tu tiež tipy na výlet a zaujímavé poznatky z histórie.</p>
        <p>Je to zároveň miesto, kde môžete zdieľať svoje zážitky a fotografie a spojiť sa s novými zaujímavými ľuďmi, o ktorých ste možno doteraz nevedeli. </p>
        <p class="closing">Veríme, že si tu každý z nás pre seba nájde ´´to pravé orechové´´, pretože MY SME JEDĽOVÉ KOSTOĽANY</p>
      </div>
      <div class="right-grid"></div>
    </div>
  </div>
</template>

<script>

// import BlockCardPanel from "@/components/BlockCardPanel";
import BannerPanel from "@/components/BannerPanel";
export default {
  name: 'HomePage',
  components: {BannerPanel},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/*kocka muz-zena*/

:root{
  --deft:100px;
}

@media (min-width: 868px) {
  .content-grid {
    display: grid;
    grid-template-columns: 30% 40% 30%;
    margin-top: 20px;
  }
  .left-grid {
    background-image: url("@/assets/images/muz.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  .right-grid {
    background-image: url("@/assets/images/zena.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.content-grid {
  margin-top: 40px;
}

.content-grid p {
  font-size: 21px;
  line-height: 1.4;
  margin: 0 0 20px;
}

.content-grid p:last-of-type {
  margin-bottom: 0;
}
</style>
