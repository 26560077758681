<template>
  <div class="banner"></div>
</template>

<script>

export default {
  name: 'BannerPanel-Krojove',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media (min-width: 868px) {
  .banner {
    background: url("@/assets/krojove/header-background-2.jpg") no-repeat center;
    height: 350px;
    overflow: hidden;
    position: relative;
    background-size: cover;
    text-align: center;
    justify-content: center;
    animation: change 30s infinite ease-in-out;
  }

  @keyframes change {
    0% {
      background-image: url("@/assets/krojove/header-background-2.jpg");
    }
    17% {
      background-image: url("@/assets/krojove/header-background-1.jpg");
    }
    34% {
      background-image: url("@/assets/krojove/header-background-3.jpg");
    }
    51% {
      background-image: url("@/assets/krojove/header-background-4.jpg");
    }
    68% {
      background-image: url("@/assets/krojove/header-background-5.jpg");
    }
    85% {
      background-image: url("@/assets/krojove/header-background-6.jpg");
    }
    100% {
      background-image: url("@/assets/krojove/header-background-2.jpg");
    }
  }
}
@media (max-width: 868px) {
  .banner {
    background: url("@/assets/krojove/header-background-2m.jpg") no-repeat center;
    height: 250px;
    overflow: hidden;
    position: relative;
    background-size: cover;
    text-align: center;
    justify-content: center;
    animation: change 30s infinite ease-in-out;
  }

  @keyframes change {
    0% {
      background-image: url("@/assets/krojove/header-background-2m.jpg");
    }
    17% {
      background-image: url("@/assets/krojove/header-background-1m.jpg");
    }
    34% {
      background-image: url("@/assets/krojove/header-background-3m.jpg");
    }
    51% {
      background-image: url("@/assets/krojove/header-background-4m.jpg");
    }
    68% {
      background-image: url("@/assets/krojove/header-background-5m.jpg");
    }
    85% {
      background-image: url("@/assets/krojove/header-background-6m.jpg");
    }
    100% {
      background-image: url("@/assets/krojove/header-background-2m.jpg");
    }
  }
}

</style>
