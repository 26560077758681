import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import VueGoogleMaps from '@fawmi/vue-google-maps';
import Vue3Geolocation from 'vue3-geolocation';
import VueMeta from 'vue-meta';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faInstagram);

const app = createApp(App);

app.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyAkNvvevv7MYqH44CRI1kPIU9D0D4eIRDs',
    },
})
    .use(VueMeta, {
        // optional pluginOptions
        refreshOnceOnNavigation: true,
    })
    .use(router)
    .use(Vue3Geolocation)
    .component('font-awesome-icon', FontAwesomeIcon)
    .mount('#app');