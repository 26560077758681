<template>
  <h1 class="grafic-font">Železničné mosty cez rieku Žitavu</h1>
  <p>Jedľové Kostoľany neboli s okolitými dedinami ako Hostie a Topoľčianky vždy spojené len asfaltovými cestami a
    lesnými chodníkmi. Dnes si už málokto spomenie na zarastený val v okolí dediny, kadiaľ viedla v časoch dávno minulých
    úzkokoľajka. Táto úzkorozchodná železnica zaisťovala dedinčanom v Jedľových Kostoľanoch nádeje na lepšie zajtrajšky
    najmä v období 1.sv. vojny, hospodárskej krízy a v neposlednom rade 2.sv. vojny.</p>
  <br/>
  <div class="content-grid">
    <div>
      <p>
        Prvú železnicu začal budovať už v roku 1886 Štefan Keglewich. Jednalo sa však o konskú železnicu. Konská
        železnica
        bola predchodca našej úzkokoľajky. Túto konskú železnicu prišla pomôcť budovať rota ženijného vojska, jednalo sa
        o
        úsek Topoľčianky- Hostie- Jedľové Kostoľany v dĺžke 15km. Súžila na zvoz dreva z priľahlých lesov ale aj na zvoz
        uhlia z uhoľnej bane Viktória. V roku 1890 prechádza železnica do vlastníctva Karla Ľudovíta Habsburga brata
        cisára
        Františka Jozefa. Ten železnicu ale aj priľahlé panstvo vrátane Topoľčianskeho zámku, venoval arcikniežaťu
        Jozefovi
        Augustínovi Habsburgovi. Ten uzavrel zmluvu o ťažbe dreva pre roky 1915-1935. Drevo z Jedľových Kostolian sa
        vyvážalo až do Viedne a Prahy. V roku 1915 sú na stavbu železnice dovedení talianski zajatci z prvej svetovej
        vojny. Títo
        zajatci mali za úlohu postaviť železnicu na zvážanie dreva z okolitých lesov. Pri stavbe železnice boli na
        viacerých
        miestach použité prvky z pôvodnej konskej železnice, čo výrazne urýchlilo proces výstavby. Ale aj napriek tomu
        sa
        jednalo ťažké zemné práce za ťažkých podmienok a len s ručnými nástrojmi. Aj napriek tomu mala úzkokoľajka dĺžku
        po
        dokončení 30,5km. Talianski zajatci boli ubytovaní v baníckej osade pri bani Viktória a v osade Lúčno. Trať
        vychádzala zo Zlatých Moraviec cez Hostie do Karlovej doliny pod Husárovú a druhá vetva smerom na Jedľové
        Kostoľany, kde
        končila v Žiaroch.</p>
      <br/>
      <p>
        Po konci 1. svetovej vojny majetok Habsburgovcov prešiel do rúk štátu. Na trati železnice nastalo viacero zmien.
        Azda najväčšia bola stavba výhrevne pre aktiváciu a prípravu parného rušňa. K lesnej úzkokoľajke patrila aj
        dieselová
        lokomotíva s dvomi vagónmi., ktorá sa volala Montania. Montania slúžila ako každodenný dopravný prostriedok pre
        chlapov a ženy pracujúcich v lese. V letných mesiacoch vláčik Montania rozvážal ľudí smerom do Hostí až do
        Karlovej
        doliny na zábavné nedeľné odpoludnia.
        V rokoch 1956-1962 nastáva zmena vo zvoze dreva z okolitých lesov. Začína sa budovať sieť asfaltových lesných
        ciest,
        ktoré slúžia na zvoz dreva. Automobilová doprava v roku 1959 úplne vytlačila železničnú dopravu.
      </p>
    </div>
    <div>
      <div class="most-cierny"></div>
      <div class="most-turna1"></div>
      <div class="most-turna2"></div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CiernyMostComponent',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media (min-width: 868px) {
  p {
    font-size: 120%;
  }
  .content-grid {
    display: grid;
    grid-template-columns: 60% 40%;
  }
}

.most-cierny {
  background-image: url("@/assets/images/miesta/cierny-most1.jpg");
  height: 40vh;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.most-turna1 {
  background-image: url("@/assets/images/miesta/most-turna1.jpg");
  height: 40vh;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.most-turna2 {
  background-image: url("@/assets/images/miesta/most-turna2.jpg");
  height: 40vh;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

</style>
