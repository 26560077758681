<template>
  <div class="banner"></div>
</template>

<script>

export default {
  name: 'BannerPanel',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

@media (min-width: 868px) {
  .banner{
    background: url("@/assets/images/header-background-1.jpg") no-repeat center;
    height: 350px;
    overflow: hidden;
    position: relative;
    background-size: cover;
    text-align: center;
    justify-content: center;
    animation: change 45s infinite ease-in-out;
    -webkit-backface-visibility: hidden;
  }
  @keyframes change{
    0%
    {
      background-image: url("@/assets/images/header-background-1.jpg");
    }
    17%
    {
      background-image: url("@/assets/images/header-background-2.jpg");
    }
    34%
    {
      background-image: url("@/assets/images/header-background-3.png");
    }
    51%
    {
      background-image: url("@/assets/images/header-background-4.jpg");
    }
    68%
    {
      background-image: url("@/assets/images/header-background-5.jpg");
    }
    85%
    {
      background-image: url("@/assets/images/header-background-6.jpg");
    }
    100%
    {
      background-image: url("@/assets/images/header-background-1.jpg");
    }
  }
}
@media (max-width: 868px) {
  .banner{
    background: url("@/assets/images/header-background-1m.jpg") no-repeat center;
    height: 250px;
    overflow: hidden;
    position: relative;
    background-size: cover;
    text-align: center;
    justify-content: center;
    animation: change 45s infinite ease-in-out;
    -webkit-backface-visibility: hidden;
  }
  @keyframes change{
    0%
    {
      background-image: url("@/assets/images/header-background-1m.jpg");
    }
    17%
    {
      background-image: url("@/assets/images/header-background-2m.jpg");
    }
    34%
    {
      background-image: url("@/assets/images/header-background-3m.png");
    }
    51%
    {
      background-image: url("@/assets/images/header-background-4m.jpg");
    }
    68%
    {
      background-image: url("@/assets/images/header-background-5m.jpg");
    }
    85%
    {
      background-image: url("@/assets/images/header-background-6m.jpg");
    }
    100%
    {
      background-image: url("@/assets/images/header-background-1m.jpg");
    }
  }
}
</style>
