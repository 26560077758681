<template>
  <h1 class="grafic-font">TOM Čerti Jedľové Kostoľany</h1>

  <p>Turistický oddiel mládeže TOM Čerti vznikol v roku 1988. Od roku vzniku je jeho vedúcim Rudolf Bošiak, ktorý je
  zároveň aj jeho zakladateľom. Školopovinnú mládež podnecovali a organizovali František Gúcky a Štefan Šedivý. Už od
    svojho založenia je TOM Čerti veľmi aktívnym oddielom, čo dokumentuje aj jeho kronika.</p>
  <br/>
  <p>
  TOM Čerti každoročne organizuje mnohé akcie. Už od jeho vzniku sa druhú januárovú a poslednú septembrovú sobotu koná
  pochod Čertovská 30-tka. Počas posledných rokov sa tradíciou stalo usporadúvanie autobusových výletov v jarných a
  jesenných mesiacoch. Cieľom týchto exkurzií bývajú okrem prírodných pamiatok aj pamiatky historické. Ešte neboli
  spomenuté nespočetné výlety na Veľký Inovec a hrad Hrušov. Tieto sú pomerne často cieľom našej cesty či už pri pešej
  turistike, alebo cyklistike
  </p>
  <div class="content-grid-inner2">
    <div>
      <div class="tomCerti"></div>
    </div>
    <div>
      <br/>
      <p style="text-align: left;"><b>E-mail: </b>tomcerti@gmail.com</p>
    </div>
  </div>


</template>

<script>

export default {
  name: 'BriezokComponent',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media (min-width: 868px) {
  p {
    font-size: 120%;
  }
  .content-grid-inner2 {
    display: grid;
    grid-template-columns: 60% 40%;
  }

}

.tomCerti {
  height: 40vh;
  background-image: url("@/assets/images/tomCerti.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 5vh;
}
</style>
