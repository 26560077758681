<template>
  <component :is="currentBanner"></component>
  <div class="content-grid">
    <div class="sidenav">
      <a style="padding-left: 1em; border-bottom: double;" href="#"
          v-for="tab in tabs"
          :key="tab"
          :class="['tab-button', { active: currentTab === tab.comp }]"
          @click="currentTab = tab.comp; currentBanner = tab.banner"
      >
        <div class="leftmenu grafic-font">{{ tab.name }}</div>
      </a>
    </div>
    <div class="main" style="min-height: 60vh; margin-top: 20px">
      <component :is="currentTab" class="tab"></component>
    </div>
  </div>
</template>

<script>

import BannerPanel from "@/components/BannerPanel";
import BriezokComponent from "@/components/BriezokComponent";
import HasiciComponent from "@/components/HasiciComponent";
import TomCertiComponent from "@/components/TomCertiComponent";
import ZakladnaSkolaComponent from "@/components/ZakladnaSkolaComponent";
import BannerPanelKrojove from "@/components/BannerPanel-Krojove";
export default {
  props: ['component'],
  name: 'OrganizaciePage',
  components: {BannerPanel, BannerPanelKrojove, BriezokComponent, HasiciComponent, ZakladnaSkolaComponent, TomCertiComponent},
  data() {
    return {
      currentBanner:'BannerPanelKrojove',
      tabs: [{comp:'BriezokComponent', name:'FS Briežok', banner:'BannerPanelKrojove'},
        {comp:'HasiciComponent', name: 'Dobrovoľný hasičský zbor', banner: 'BannerPanel'},
        {comp:'ZakladnaSkolaComponent', name: 'Základná škola', banner: 'BannerPanel'},
        {comp:'TomCertiComponent', name: 'TOM Čerti', banner: 'BannerPanel'}],
      currentTab: this.component,
    }
  },
  created() {
    if (this.component != null) {
      this.currentBanner = this.tabs.find(value => value.comp ===(this.component)).banner;
    } else {
      this.currentTab = 'BriezokComponent';
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  font-family: "Lato", sans-serif;
}

.sidenav {
  width: available;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
}

.sidenav a {
  padding: 6px 8px 6px 16px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
}

.sidenav a:hover {
  color: #007bff;
}

.leftmenu :hover{
  color: #0000;
  background:
      linear-gradient(90deg,white 50%,#007bff 0)
      var(--_p,100%)/200% no-repeat;
  -webkit-background-clip: text;
  transition: 1s;
}

@media (min-width: 868px) {
  .sidenav {
    border-top-right-radius: 50px;
  }
  .content-grid {
    display: grid;
    grid-template-columns: 15% 85% ;
  }
}
</style>
