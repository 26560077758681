<template>
  <h1 class="grafic-font">Základná a materská škola</h1>
  <p>Škola. Na pohľad taká istá, ako všetky ostatné. Ale iba na pohľad.</p>
  <p>Žiaci, tak ako aj dnes, sa venovali mimoškolskej činnosti, športovej činnosti, turizmu, rozvoju prírodovedného poznania, spevu či dejinám Slovenska. Život plynul, žiaci prichádzali a odchádzali, z malých detí sa stali dospelí, ktorých deti opäť chodia do našej školy.</p>
  <div class="content-grid-inner">
    <div></div>
    <div>
      <br/>
      <p>Jej história sa začala písať okolo roku 1394.  Prvá budova školy bola z dreva, tvorila ju jedna učebňa a byt učiteľa, ktorý bol zároveň aj organistom. Škola bola majetkom rímsko-katolíckej cirkvi. Výstavba súčasnej budovy školy sa začala 15.08.1960.</p>
      <br/>
      <p>Dnešná škola žije mnohými aktivitami. Školský rok je kolobehom udalostí, ktoré ho napĺňajú podobne ako v minulosti.</p>
    </div>
    <div>
      <div class="skola2"></div>
    </div>
  </div>
  <div class="content-grid-inner2">
    <div>
      <div class="skola1"></div>
    </div>
    <div>
      <br/>
      <p style="text-align: left;"><b>Zriaďovateľ:</b> Obec Jedľové Kostoľany</p>
      <p style="text-align: left;"><b>Meno školy:</b> Základná škola, Jedľové Kostoľany 75</p>
      <p style="text-align: left;"><b>Telefón:</b> +421/0376338223</p>
      <p style="text-align: left;"><b>E-mail:</b> skolajk@centrum.sk</p>
      <p style="text-align: left;"><b>IČO:</b> 37 86 50 64</p>
      <p style="text-align: left;"><b>DIČ:</b> 202 164 044</p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'BriezokComponent',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media (min-width: 868px) {
  p{
    font-size: 120%;
  }
  .content-grid-inner {
    display: grid;
    grid-template-columns: 20% 60% 20%;
  }

  .content-grid-inner2 {
    display: grid;
    grid-template-columns: 60% 40%;
  }

  .skola1 {
    height: 40vh;
    background-image: url("@/assets/images/skola1.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: 5vh;
  }

  .skola2 {
    height: 20vh;
    background-image: url("@/assets/images/skola2.png");
    background-size: contain;
    background-repeat: no-repeat;
  }
}
</style>
