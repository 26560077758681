<template>
  <div>
    <h1 class="grafic-font">Folklórna skupina Briežok</h1>
    <br/>
    <p>Folklórna skupina Briežok vznikla v roku 2013. Venuje sa obnoveniu gajdošskej kultúry v obci.</p>
    <p>Okrem vystúpení doma či v zahraničí skupina pravidelne organizuje vianočné koncerty, tradičné tancovačky či
      výchovné koncerty.
      Venuje sa osvetovej a výskumnej činnosti. Členky skupiny pred niekoľkými rokmi zriadili digitálny archív, ktorý
      mapuje odievanie v našej obci od prelomu 19. a 20. storočia.
      Momentálne má skupina ľudovú hudbu s 5 dievčatami a spevácku a hudobnú zložku pozostávajúcu z 10 mladších
      dievčat.</p>
    <br/>
  </div>
  <div class="content-grid-inner">
    <div>
      <p>Medzi najväčšie úspechy dievčat patria dve prvé miesta (Dominika Cigáňová, Vivien Kukučková) na medzinárodnej
        gajdošskej súťaži v Oravskej Polhore.
        Okrem nich majú na svojom konte úspechy aj malé speváčky - strieborné pásmo z okresného kola speváckej súťaže
        v
        Nitre v kategórii spevácke skupiny, zlaté pásmo v speváckej súťaži v Oravskej Polhore a striebro na kraji v
        podaní našej speváčky Simonky Mihálovej.</p>
      <p>Folklórna skupina Briežok kedykoľvek rada privíta všetky deti, ktoré majú záujem o folklór a príjemne
        strávené
        chvíle plné hudby a zábavy :)</p>
    </div>
    <div class="content-grid-images1">
      <div class="zena"></div>
      <div class="muz"></div>
    </div>
  </div>
  <div class="content-grid-images2">
    <div class="briezok1"></div>
    <div class="briezok2"></div>
    <div class="briezok3"></div>
    <div class="briezok4"></div>
  </div>
</template>

<script>

export default {
  name: 'BriezokComponent',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media (max-width: 868px) {

}

@media (min-width: 868px) {
  .content-grid-inner {
    display: grid;
    grid-template-columns: 60% 40%;
  }
  p{
    font-size: 120%;
  }
  .content-grid-images1 {
    display: grid;
    grid-template-columns: 50% 50%;
  }
  .muz {
    height: 30vh;
    background-image: url("@/assets/images/muz.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .zena {
    height: 30vh;
    background-image: url("@/assets/images/zena.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}
.content-grid-images2 {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
}
.briezok1 {
  height: 25vh;
  background-image: url("@/assets/krojove/briezok1.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.briezok2 {
  height: 25vh;
  background-image: url("@/assets/krojove/briezok2.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.briezok3 {
  height: 25vh;
  background-image: url("@/assets/krojove/briezok3.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.briezok4 {
  height: 25vh;
  background-image: url("@/assets/krojove/briezok4.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

</style>
