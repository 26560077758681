<template>
  <h1 class="grafic-font">Rozhľadňa na Drieňovej</h1>
  <template>
    <div>
      <h1 class="grafic-font">Rozhľadňa na Drieňovej</h1>
      <div class="drienova"></div>
    </div>
  </template>
  <p>
    Rozhľadňa na Drieňovej sa vypína na Tribečskom vrchu s nadmorskou výškou 551,4 metrov a dominuje obci Jedľové
    Kostoľany. Táto 5 metrov vysoká oceľová konštrukcia bola postavená v roku 2010 a nahrádza staršiu kovovo-drevenú
    rozhľadňu, ktorá bola poškodená poveternostnými vplyvmi. Nová rozhľadňa prináša návštevníkom
    prekrásne panoramatické výhľady na široké okolie.
  </p>
  <p>
    Pohľad z rozhľadne zahŕňa mnohé zaujímavé miesta, ako je napríklad Pohronský Inovec, ktorý je obľúbeným cieľom
    turistov. Okrem toho možno vidieť aj zrúcaninu hradu Hrušov, ktorá svedčí o histórii regiónu. Ďalej sa naskytá
    výhľad na obec Hostie.
  </p>
  <p>
    Výhľad z rozhľadne pokračuje smerom k žirianskej Žibrici a pohoriu Zobor, ktoré sú obľúbenými miestami pre turistiku a relaxáciu.
    Na západ možno vidieť lesy cez Hlbokú dolinu, ktorá je domovom bohatej flóry a fauny. Okrem toho sa tu nachádza zubri obor v Topoľčiankach,
    kde žije populácia európskych zubrov.
  </p>
  <p>
    Južne od vrchu Drieňová sa nachádza kopec Včelár, ktorý je obklopený prírodnou krásou a poskytuje príležitosti pre
    túry a pikniky.
  </p>
  <p>Cesta k rozhľadni je značkovaný turistický chodník, ktorý začína v Jedľových Kostolianoch. Trasa je jednoduchá,
    nevyžaduje si žiadne špeciálne zručnosti alebo skúsenosti, a je prístupná pre všetky vekové kategórie, či už pre
    rodiny s deťmi alebo starších ľudí. Počas cesty sa môžete pokochať prírodnými krásami a odpočívať na miestach
    určených pre turistov.</p>

<div class="drienova"></div>
</template>

<script>

export default {
  name: 'DrienovaComponent',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media (min-width: 868px) {
  p {
    font-size: 120%;
  }
}
.drienova {
  height: 50vh;
  background-image: url("@/assets/images/miesta/drienova2.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

</style>
