<template>
  <!-- Navigation bar -->
  <nav class="navbar">
    <div class="content">
      <div class="logo"><img src="@/assets/logo/kostolany.png" alt="logo" width="70" height="50"> <a href="#">My sme Jedľové Kostoľany</a></div>
      <ul class="menu-list">
        <router-link class="cancel-btn" to="/">
          <li><img class="icons" src="@/assets/icons/menu/info.png" alt=""/><a class="grafic-font" href="#">O nás</a></li>
        </router-link>
        <router-link class="cancel-btn" to="/map">
          <li><img class="icons" src="@/assets/icons/menu/map.png" alt=""><a class="grafic-font" href="#">Interaktívna mapa</a></li>
        </router-link>
        <router-link class="cancel-btn" to="/organizacie">
          <li><img class="icons" src="@/assets/icons/menu/organization.png" alt=""><a class="grafic-font" href="#">Združenia a organizácie</a></li>
        </router-link>
<!--        <router-link class="cancel-btn" to="/jednotlivci">-->
<!--          <li><a class="grafic-font" href="#">Jednotlivci</a></li>-->
<!--        </router-link>-->
<!--        <router-link class="cancel-btn" to="/story">-->
<!--          <li><a class="grafic-font" href="#">Príbehy</a></li>-->
<!--        </router-link>-->
        <router-link class="cancel-btn" to="/miesta">
          <li><img class="icons" src="@/assets/icons/menu/places.png" alt=""><a class="grafic-font" href="#">Miesta</a></li>
        </router-link>
        <router-link class="cancel-btn" to="/contact">
          <li><img class="icons" src="@/assets/icons/menu/contact.png" alt=""><a class="grafic-font" href="#">Kontakt</a></li>
        </router-link>
      </ul>
      <div class="icon menu-btn">
        <i class="fas fa-bars"></i>
      </div>
    </div>
  </nav>
</template>

<script>

export default {
  name: 'HeaderPanel',
  mounted() {
    const icons = document.querySelectorAll(".icons");
    const navbar = document.querySelector(".navbar");
    const menu = document.querySelector(".menu-list");
    const menuBtn = document.querySelector(".menu-btn");
    const cancelBtn = document.querySelectorAll(".cancel-btn");
    menuBtn.onclick = function () {
      menu.classList.add("active");
      menuBtn.classList.add("hide");

    }

    cancelBtn.forEach((butt) => {
      butt.onclick = function () {
        menu.classList.remove("active");
        menuBtn.classList.remove("hide");

      }
    });

    window.onscroll = function () {
      this.scrollY > 20 ? navbar.classList.add("sticky") : navbar.classList.remove("sticky");
      this.scrollY > 20 ? icons.forEach(value => value.classList.add("inverse")): icons.forEach(value => value.classList.remove("inverse"));
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
/*menu*/
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}
/* custom scroll bar */
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #888;
}
::selection{
  background: rgb(0,123,255,0.3);
}
.content{
  margin: auto;
  padding: 0px 30px;
}
.navbar{
  border-bottom-right-radius: 50px;
  border-bottom-left-radius: 50px;
  position: fixed;
  z-index: 5;
  width: 100%;
  background: rgba(6, 10, 7, 0.3);
  padding: 25px 0;
  transition: all 0.3s ease;
}
.navbar.sticky{
  padding: 10px 0;
  background: #1b1b1b;
  opacity: 1;
  box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.1);
}

.inverse{
  filter: invert(100%);
}

.navbar.sticky a{
  color: white;
}

.navbar .content{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar .logo a{
  color: #fff;
  font-size: 30px;
  font-weight: 600;
  text-decoration: none;
  font-family: Sancreek,serif;
}

.grafic-font {
  font-family: Sancreek,serif;
}

.navbar .menu-list{
  display: inline-flex;
}

.menu-list{
  display: flex;
}

.menu-list li{
  list-style: none;
}

.menu-list li a{
  display: flex;
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  margin-left: 25px;
  text-decoration: none;
  transition: all 0.3s ease;
}

.menu-list li a:hover{
  color: #007bff;
}

.icon{
  color: #ffffff;
  font-size: 20px;
  cursor: pointer;
  display: none;
}

.menu-list li {
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.menu-list li img {
  margin-bottom: 5px;
}

.menu-list li a {
  display: flex;
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  margin-left: 25px;
  text-decoration: none;
  transition: all 0.3s ease;
}

@media (min-width: 600px) and (max-width: 868px) {
  body.disabledScroll{
    overflow: hidden;
  }
  .icon{
    background-image: url("@/assets/icons/menu.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top;
    display: block;
    height: 50px;
    width: 50px;
    text-align: center;
    line-height: 50px;
    border-radius: 50%;
    z-index: 9;
  }
  .icon.hide{
    display: none;
  }
  .navbar .menu-list{
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background: #222;
    display: block;
    padding: 40px 0;
    text-align: center;
    clip-path: circle(25px at calc(100% - 55px) calc(0% + 55px));
    transition: all 0.3s ease;
  }
  .navbar.sticky .menu-list{
    clip-path: circle(25px at calc(100% - 55px) calc(0% + 40px));
  }
  .navbar .menu-list.active{
    clip-path: circle(75%);
  }
  .navbar .menu-list li{
    margin-top: 45px;
  }
  .navbar .menu-list li a{
    font-size: 23px;
  }
}

@media (max-width: 600px) {
  body.disabledScroll{
    overflow: hidden;
  }
  .icons {
    filter: invert(100%);
  }
  .icon{
    background-image: url("@/assets/icons/menu.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top;
    display: block;
    height: 50px;
    width: 50px;
    text-align: center;
    line-height: 50px;
    border-radius: 50%;
    z-index: 9;
  }
  .icon.hide{
    display: none;
  }
  .navbar .menu-list{
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background: #222;
    display: block;
    padding: 40px 0;
    text-align: center;
    clip-path: circle(25px at calc(100% - 50px) calc(0% + 70px));
    transition: all 0.3s ease;
  }
  .navbar.sticky .menu-list{
    clip-path: circle(25px at calc(100% - 50px) calc(0% + 57px));
  }
  .navbar .menu-list.active{
    clip-path: circle(75%);
  }
  .navbar .menu-list li{
    margin-top: 45px;
  }
  .navbar .menu-list li a{
    font-size: 23px;
    border-bottom: double;
  }

}
/*end menu*/


</style>
