import { createRouter, createWebHistory} from 'vue-router'
import HomePage from "@/components/HomePage";
import InteractiveMapPage from "@/components/InteractiveMapPage";
import OrganizaciePage from "@/components/OrganizaciePage";
import ContactPage from "@/components/ContactPage";
import JednotlivciPage from "@/components/JednotlivciPage";
import storyPage from "@/components/StoryPage";
import MiestaPage from "@/components/MiestaPage";

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: [
        {
            path: '/',
            component: HomePage
        },
        {
            path: '/organizacie',
            component: OrganizaciePage
        },
        {
            path: '/organizacie/:component',
            component: OrganizaciePage,
            props: true
        },
        {
            path: '/jednotlivci',
            component: JednotlivciPage
        },
        {
            path: '/map',
            component: InteractiveMapPage
        },
        {
            path: '/story',
            component: storyPage
        },
        {
            path: '/miesta',
            component: MiestaPage
        },
        {
            path: '/miesta/:component',
            component: MiestaPage,
            props: true
        },
        {
            path: '/contact',
            component: ContactPage
        }
    ]
})

export default router;
