<template>
  <h1 class="grafic-font">Lavička - Hujavovce</h1>
    <div class="lavicka2"></div>
</template>

<script>

export default {
  name: 'LavickaComponent',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media (min-width: 868px) {
}

.lavicka2 {
  height: 50vh;
  background-image: url("@/assets/images/miesta/lavicka2.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

</style>
