<template>
  <banner-panel/>
  <div class="content" role="main">

    <h1 class="grafic-font">Kontakt</h1>
    <h1/>
    <div class="content-grid" style="min-height: 55vh">
      <div class="left-grid"/>
      <div class="ceneter-grid">
        <b>Email : </b>
        <a href="mailto:otazky@mysmejedlovekostolany.sk">
          otazky@mysmejedlovekostolany.sk
        </a>
        <br>
        <b>Instagram : </b>
        <a href="https://www.instagram.com/mysmejedlovekostolany.sk" target="_blank" rel="noopener noreferrer">
          www.instagram.com/mysmejedlovekostolany
        </a>
      </div>
      <div class="right-grid"/>
    </div>
  </div>
</template>

<script>
import BannerPanel from "@/components/BannerPanel";
export default {
  name: 'ContactPage',
  components: {BannerPanel},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
:root{
  --deft:100px;
}

@media (min-width: 868px) {
  .content-grid {
    display: grid;
    grid-template-columns: 30% 40% 30%;
  }
  .left-grid {
    background-image: url("@/assets/images/muz.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  .right-grid {
    background-image: url("@/assets/images/zena.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}
</style>
