<template>
  <banner-panel/>

  <div class="content-grid">
    <div class="sidenav hide">
      <div class="filter">
        <a class="filter grafic-font">Filtre :</a>
      </div>
      <div class="leftmenu" style=" padding-left: 1em; border-bottom: double;" v-for="(value, key, index) in markers" :key="index">
        <label class="form-control">
        <input type="checkbox" :id="key" v-model="markers[key].show">
          <div class="title">{{value.title}}</div>
        </label>
      </div>
    </div>
    <div class="content-grid-arrow">
      <div style="background-color: #111111;"></div>
      <div class="arrowContainer">
        <div class="arrowDown"></div>
        <div class="arrowUp hide"></div>
      </div>
      <div style="background-color: #111111"></div>
    </div>
    <div class="map" style=" height: 100vh">
      <GMapMap
          :center="center"
          :zoom="14"
          map-type-id='hybrid'
          style=" height: 100vh"
          :options="{
                      styles: [
          {
            featureType: 'poi',
            stylers: [{ visibility: 'off' }],
          }]
                }">
        <GMapMarker
            :key="index"
            v-for="(m, index) in markers"
            :position="m.position"
            :visible="m.show"
            :icon="m.icon"
            :clickable="true"
            :draggable="false"
            @click="center=m.position; openMarker(m.id)"
        >
          <GMapInfoWindow
              :closeclick="true"
              @closeclick="openMarker(null)"
              :opened="openedMarkerID === m.id"
              :options=" {
              pixelOffset: {
                width: 0, height: 0
              },
       }"
          >
            <div v-if="m.show">
              <div><h2 class="grafic-font">{{ m.title }}</h2></div>
              <div>{{ m.text }}</div>
              <img v-bind:src="m.image">
              <div>{{ m.textUnder }}</div>
              <div v-if="m.routePath">
                <router-link  v-bind:to="m.routePath">
                  <button class="relink relinkhover" >Prejsť na stránku</button>
                </router-link>
              </div>
            </div>
          </GMapInfoWindow>
        </GMapMarker>
      </GMapMap>
    </div>

  </div>
</template>
<script>
import BannerPanel from "@/components/BannerPanel";
export default {
  name: 'App',
  components: {BannerPanel},
  created() {
    this.$getLocation()
        .then((coordinates) => {
          this.markers.forEach(marker => {
            if (marker.id === 14) {
              marker.position.lat = coordinates.lat;
              marker.position.lng = coordinates.lng;
            }
          })
        })
        .catch((error) => {
          console.log(error);
        });
  },
  mounted() {
    const sidenav = document.querySelector(".sidenav");
    const arrowUp = document.querySelector(".arrowUp");
    const arrowDown = document.querySelector(".arrowDown");
    arrowUp.onclick = function () {
      arrowUp.classList.add("hide");
      arrowDown.classList.remove("hide");
      sidenav.classList.add("hide");
    }

    arrowDown.onclick = function () {
      arrowUp.classList.remove("hide");
      arrowDown.classList.add("hide");
      sidenav.classList.remove("hide");
    }
  },
  data() {
    return {
      openedMarkerID: null,
      center: {lat: 48.471819392595535, lng: 18.496960164245948},
      markers: [
        {
          id: 1,
          show: true,
          routePath: '/miesta/LavickaComponent',
          title:'Lavička Hujavoce',
          text:'',
          textUnder:'',
          image:require('@/assets/images/miesta/lavicka-min1.jpg'),
          icon:require('@/assets/icons/lavicka-point-red.png'),
          position: {
            lat: 48.4734340270952, lng: 18.515630558899613
          },
        },
        {
          id: 2,
          show: true,
          routePath: '/miesta/TurnaComponent',
          title:'Živánska veža',
          text:'',
          textUnder:'',
          image:require('@/assets/images/miesta/turna-min1.png'),
          icon:require('@/assets/icons/tower-point-red.png'),
          position: {
            lat: 48.46501361486378, lng: 18.518551469032126
          },
        },
        {
          id: 3,
          show: true,
          routePath: '/miesta/CiernyMostComponent',
          title:'Čierny most',
          text:'',
          textUnder:'',
          image:require('@/assets/images/miesta/cierny-most-min1.jpg'),
          icon:require('@/assets/icons/most-point.png'),
          position: {
            lat: 48.45953039196661, lng: 18.497545938629468
          },
        },
        {
          id: 4,
          show: true,
          routePath: '/organizacie/ZakladnaSkolaComponent',
          title:'Základná a materská škola',
          text:'',
          textUnder:'Adresa: Jedľové Kostoľany 74, 951 96 Jedľové Kostoľany',
          image:require('@/assets/images/miesta/skola-mini.jpg'),
          icon:require('@/assets/icons/skola2-point-red.png'),
          position: {
            lat: 48.46882978379255, lng: 18.499660715341317
          },
        },
        {
          id: 5,
          show: true,
          routePath: '/organizacie/HasiciComponent',
          title:'Hasičská zbrojnica',
          text:'',
          textUnder:'Adresa: Jedľové Kostoľany 685, 951 96 Jedľové Kostoľany',
          image:require('@/assets/images/miesta/zbrojnica1-min.jpg'),
          icon:require('@/assets/icons/zbrojnica-point-red.png'),
          position: {
            lat: 48.47119654650508, lng: 18.49767414755355
          },
        },
        {
          id: 6,
          show: true,
          routePath: '',
          title:'Kaplnka pod Bošiakovcami',
          text:'',
          textUnder:'',
          image:require('@/assets/images/miesta/kaplnka-drahy-min.jpg'),
          icon:require('@/assets/icons/kaplnka-point-red.png'),
          position: {
            lat: 48.48004139989607, lng: 18.502065319423718
          },
        },
        {
          id: 7,
          show: true,
          routePath: '',
          title:'Križ',
          text:'Postavený na pamiatku Jánovi Laktišovi',
          textUnder:'',
          image:require('@/assets/images/miesta/kriz-poliany1-min.jpg'),
          icon:require('@/assets/icons/kaplnka-point-red.png'),
          position: {
            lat: 48.47810119177898, lng: 18.490395206083168
          },
        },
        {
          id: 8,
          show: true,
          routePath: '',
          title:'Kaplnka Panny Márie',
          text:'1923',
          textUnder:'',
          image:require('@/assets/images/miesta/kaplnka-dedina2-min.jpg'),
          icon:require('@/assets/icons/kaplnka-point-red.png'),
          position: {
            lat: 48.46443847044376, lng: 18.49916363261045
          },
        },
        {
          id: 9,
          show: true,
          routePath: '',
          title:'Dvojkríž',
          text:'',
          textUnder:'',
          image:require('@/assets/images/miesta/dvojrkriz2-min.jpg'),
          icon:require('@/assets/icons/dvojkriz-point-red.png'),
          position: {
            lat: 48.460357808362396, lng: 18.490014591202797
          },
        },
        {
          id: 10,
          show: true,
          routePath: '/miesta/DrienovaComponent',
          title:'Rozhľadňa na Drieňovej',
          text:'',
          textUnder:'',
          image:require('@/assets/images/miesta/drienova2-min.jpg'),
          icon:require('@/assets/icons/drienova-point-red.png'),
          position: {
            lat: 48.47515298586615, lng: 18.48583970743235
          },
        },
        {
          id: 11,
          show: true,
          routePath: '',
          title:'Kaplnka sedembolestnej Panny Márie',
          text:'1932',
          textUnder:'',
          image:require('@/assets/images/miesta/kaplnka-bresovo3-min.jpg'),
          icon:require('@/assets/icons/kaplnka-point-red.png'),
          position: {
            lat: 48.497854978341394, lng: 18.506986810676164
          },
        },
        {
          id: 12,
          show: true,
          routePath: '',
          title:'Studnička',
          text:'',
          textUnder:'',
          image:require('@/assets/images/miesta/studnicka3-min.jpg'),
          icon:require('@/assets/icons/studnicka-point-red.png'),
          position: {
            lat: 48.490827, lng: 18.4953333
          },
        },
        {
          id: 13,
          show: true,
          routePath: '',
          title:'Križ',
          text:'',
          textUnder:'',
          image:require('@/assets/images/miesta/kriz-bresovo1-min.jpg'),
          icon:require('@/assets/icons/kriz-point-red.png'),
          position: {
            lat: 48.49495317389825, lng: 18.500055029211797
          },
        },
        {
          id: 14,
          show: true,
          routePath: '',
          title:'Vaša poloha',
          text:'',
          textUnder:'',
          image:'',
          icon:require('@/assets/icons/point-red.png'),
          position: {
            lat: 0, lng: 0
          },
        }
      ]
    }
  },
  methods: {
    openMarker(id) {
      this.openedMarkerID = id
    }
  }
}
</script>

<style scoped>
body {
  font-family: "Lato", sans-serif;
}

.form-control {
  font-family: system-ui, sans-serif;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.1;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;
  color: white;
  padding-bottom: 1rem;
}

.form-control + .form-control {
  margin-top: 1em;
}

.form-control--disabled {
  cursor: not-allowed;
}
.leftmenu :hover{
  color: #0000;
  background:
      linear-gradient(90deg,white 50%,#007bff 0)
      var(--_p,100%)/200% no-repeat;
  -webkit-background-clip: text;
  transition: 1s;
}

input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 1.30em;
  height: 1.30em;
  border: 0.15em solid currentColor;
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}

input[type="checkbox"]::before {
  content: "";
  width: 1em;
  height: 1em;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  background-color: #007bff;
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}

input[type="checkbox"]:focus {
  outline: max(2px, 0.15em) solid currentColor;
  outline-offset: max(2px, 0.15em);
}

input[type="checkbox"]:disabled {
  cursor: not-allowed;
}

.sidenav {
  width: available;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  transition: all 0.3s ease;
}
.sidenav a {
  padding: 6px 8px 6px 16px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
}
.sidenav a:hover {
  color: #f1f1f1;
}

.relink {
  background-color: #007bff; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
}

.relinkhover:hover {
  box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19);
}

@media (min-width: 868px) {
  .content-grid {
    display: grid;
    grid-template-columns: 15% 0 85% ;
  }

}

@media (max-width: 868px) {

  .content-grid-arrow {
    display: grid;
    grid-template-columns: 40% 20% 40% ;
    background-color: #111111;
  }
  .sidenav.hide {
    display: none;
  }

  .arrowContainer {
    background-position: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .arrowDown{
    background-image: url("@/assets/icons/arrowDown.png");
    background-color: white;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: center;
    display: block;
    height: 50px;
    width: 50px;
    line-height: 50px;
    border-radius: 50%;
    z-index: 9;
  }
  .arrowUp{
    background-image: url("@/assets/icons/arrowUp.png");
    background-color: white;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: center;
    display: block;
    height: 50px;
    width: 50px;
    line-height: 50px;
    border-radius: 50%;
    z-index: 9;
  }

  .arrowDown.hide{
    display: none;
  }

  .arrowUp.hide{
    display: none;
  }
}
</style>
