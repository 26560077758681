<template>
  <h1 class="grafic-font">Živánska veža - Jedľové Kostoľany</h1>
  <p>Po prvýkrát sa stretávame so Živánskou vežou v cestopisnej štúdii o Tekovskej župe z roku 1781, kde obyvatelia
    Jedľových Kostolian nazývajú vežu "Turňou". Stretneme sa aj s pomenovaním Zbojnícka veža. Veža patrila Hrušovskému
    hradu a neskôr slúžila zbojníkom, skade získala aj pomenovanie "Živánska". Podľa iných autorov to bol poľovnícky
    hrad kráľa Mateja alebo strážny hrádok.</p>
  <br/>
  <div class="content-grid-inner2">
    <div>
      <p>Umiestnenie veže tesne nad požitavskou cestou upozorňuje na dôležitosť historickej
        komunikácie, ktorá bola vežou kontrolovaná. V období ťažby zlata v ložiskách Novej Bane dôležitosť veže ešte
        vzrástla.
        Cesta, ktorá spájala Požitavie s Pohroním viedla práve popod Živánsku vežu. Vežu možno stotožniť s colnou
        stanicou,
        ktorej činnosť sa dá datovať od konca 14. storočia do konca 15. storočia. Slúžila teda na stráženie obchodnej
        cesty
        od Pohronia do údolia Žitavy.</p>
      <br/>
      <p>Strážna veža začala pustnúť po zničení Hrušovského hradu v roku 1708. Veža mala niekoľko
        podlaží. Názory na vzhľad sa však rozchádzajú. Mnohí odborníci hovoria o 5 podlažnej stavbe, v iných prameňoch
        sa
        uvádzajú štyri podlažia. Podľa obecnej kroniky veža mala výšku až 40 m a mala šesť poschodí s vchodom na prvom
        poschodí. Kým niektoré veže mali schodište vybudované v múroch, iné, ako aj Živánska používali rebríky.
        Predpokladá
        sa, že jej rozlomenie do dnešnej podoby spôsobila gravitácia a nevylučuje sa ani zemetrasenie. Stavitelia
        používali
        lámaný andezit, spojivom bola vápenná malta a riečny štrk zo Žitavy. Pôvodná prístupná cesta k veži sa
        pravdepodobne
        nachádzala severovýchodne od veže. Z archívnych prameňov sa dozvedáme, že pri veži existovalo predhradie
        prepojené k
        veži a obohnané hradným múrom. Doposiaľ sa to však nepotvrdilo. Juhovýchodne od veže je 20 m plošinka, ktorá
        slúžila
        zrejme ako hospodárske územie s miestom pre kone.</p>
    </div>
    <div>
      <div class="turna"></div>
    </div>
  </div>

</template>

<script>

export default {
  name: 'TurnaComponent',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media (min-width: 868px) {
  p {
    font-size: 120%;
  }
  .content-grid-inner2 {
    display: grid;
    grid-template-columns: 70% 30%;
  }
}
.turna {
  height: 50vh;
  background-image: url("@/assets/images/miesta/turna.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
</style>
